.epl_slider_container {
  height: 40vw !important;
  overflow: hidden;
  max-height: 70vh !important;
}

.epl-slider {
  &-slides {
    overflow: hidden;
  }
}

.epl-slider-thumb-container {
  height: 40vw !important;
  overflow: hidden !important;
  max-height: 70vh !important;

  .p .c {
    display: none;
  }
}
