.il {
  @media (--medium) {
    display: flex;
  }
}

.il-main {
  @media (--medium) {
    flex: 1 1 100%;
    width: auto;
    overflow: hidden;
  }
}

.il-sidebar {
  background: var(--color-black);
  color: var(--color-white);
  padding: var(--base-padding-large);
  @media (--medium) {
    flex: 0 0 auto;
    width: 20rem;
  }
  @media (--xlarge) {
    width: 25rem;
  }
}

.il-sidebar-contents {
  position: fixed;
  top: calc(var(--site-header--height) + calc(var(--external-gutters) / 2));
  right: 0;
  padding: var(--base-padding-large);
  @media (--medium) {
    width: 20rem;
  }
  @media (--xlarge) {
    width: 25rem;
  }
}

.il-images {
  width: 100%;
  background: var(--color-black);
  position: relative;
  div {
    outline: none;
  }
  &__image {

  }
  &__main {
    width: calc(100% - 11rem);
    overflow: hidden;
    height: auto;
    position: relative;
    &__image {
      background-position: center center;
      background-repeat: no-repeat;
      width: auto;
      background-size: cover;
      height: 80vh;
      max-height: 40vw;
    }
    .slick-slide {
      margin: 0;
      opacity: .7;
      max-height: 60rem;
      overflow: hidden;
      &.slick-current {
        opacity: 1;
      }
      div {
        display: block !important;
      }
    }
  }
  &__nav {
    width: 11rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: calc(100% - 11rem);
    height: auto;
    overflow: hidden;
    .slider {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      .slick-list {
        padding: 0 !important;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100% !important;
      }
    }
    &__image {
      background-position: center center;
      background-repeat: no-repeat;
      width: auto;
      height: 0;
      padding-bottom: 70%;
      background-size: cover;
    }
    .slick-slide {
      margin: 0;
      opacity: .7;
      &.slick-current {
        opacity: 1;
      }
      div {
        display: block !important;
      }
    }
  }
}

.il-section {
  padding: var(--external-gutters) var(--external-gutters);
  &--flush {
    padding: 0;
    margin: 0;
  }
}

.il-price {
  position: absolute;
  transform: translateY(-50%);
  display: inline-block;
  width: auto;
  background: var(--color-black);
  color: var(--color-white);
  padding: .75rem 1rem 1rem;
  margin-left: var(--external-gutters);
  font-size: 2rem;
  font-family: var(--font);
  line-height: 1;
  vertical-align: top;
}

/* Single Property Actions (save, share) */
.il-actions {
  display: flex;
  .button {
    flex: 0 1 100%;
    margin-right: var(--base-padding-medium);
    &:last-of-type {
      flex-basis: 3.125rem;
      margin-right: 0;
    }
  }
}

/* EPL Tweaks */
.epl-advanced-map-single {
  margin-bottom: 0;
}

.epl_tabbed_map_control {
  margin: 0 var(--external-gutters);
}

.map-tabs {
  display: flex;
  list-style-type: none;
  padding: 0;
  background-color: var(--color-white);
  li {
    flex: 0 1 25%;
    text-align: center;
    padding: var(--base-padding-medium);
    list-style-type: none;
    &.maptab-current {
      background-color: var(--color-light-gray) !important;
    }
  }
}
