

.c-properties-list {
  background: var(--color-black);
  padding: var(--external-gutters);
  display: flex;
  height: calc(100vh - 150px);
  flex-wrap: wrap;
  overflow-y: auto;

  * {
    color: white;
  }

  &__property {
    flex: 1 1 50%;
    padding: 1px;

    .epl-archive-entry-image {
      min-width: 100%;
      height: 0;
      padding-bottom: 70%;
      position: relative;
      overflow: hidden;

      a {
        display: block;
        transition: all .2s ease-in-out;

        &:hover {
          opacity: .7;
        }
      }

      img {
        position: absolute;
        top: -60px;
        right: -60px;
        bottom: -60px;
        left: -60px;
        width: auto;
        height: auto;
        min-width: calc(100% + 120px);
      }
    }
  }
}

.c-properties-filters {
  width: 100%;
  height: 70px;
}

.c-properties-list-map {
  height: calc(100vh - 150px);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  #epl-advanced-map {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .slider-map {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100% !important;
    }
  }
}
