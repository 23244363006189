/* EPL Shortcode for Staff Directory */
.epl-sd-shortcode-content {

}

.epl-author {
  padding: var(--external-gutters) 0;
  border-bottom: 1px solid var(--color-border);
  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    padding-bottom: 0;
    border-bottom: 0;
  }
  .entry-content {
    display: flex;
    .epl-author-box {
      &.epl-author-image {
        flex: 0 0 10rem;
        max-width: 10rem;
        align-self: center;
        a {
          display: block;
          width: 100%;
          height: 0;
          padding-bottom: 100%;
          position: relative;
          overflow: hidden;
          border-radius: 100%;
          img {
            position: absolute;
            top: 0;
            bottom: 0;
            transform: translate(-50%, -50%);
            margin: 50% auto auto 50%;
          }
        }
      }
      &.epl-author-details {
        flex: 0 1 auto;
        align-self: center;
        padding: 0 calc(var(--external-gutters) / 1.5);
        h5 {
          font-family: var(--font-secondary);
          letter-spacing: 0;
          text-transform: none;
          font-size: 1.25rem;
          font-weight: 500;
        }
        p {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.agent-finder-featured-title {
  margin-bottom: var(--base-padding-xlarge);
}

.agent-finder-featured {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: calc(var(--external-gutters) * -1);
  margin-right: calc(var(--external-gutters) * -1);
  &__agent {
    padding: 0 var(--base-padding-large);
    display: none;
    &:first-child {
      display: flex;
      flex: 0 1 100%;
      max-width: 320px;
    }
    @media (--medium) {
      display: flex;
      flex: 0 1 50%;
      max-width: 350px;
      &:first-child {
        flex: 0 1 50%;
        max-width: 350px;
      }
      &:last-child {
        display: none;
      }
    }
    @media (--large) {
      display: flex;
      flex: 0 1 33%;
      &:first-child {
        flex: 0 1 33%;
      }
      &:last-child {
        display: flex;
      }
    }
    &__image {
      flex: 0 0 70px;
      width: 70px;
      height: 70px;
      position: relative;
      overflow: hidden;
      border-radius: 100%;
      @media (--large) {
        flex: 0 0 100px;
        width: 100px;
        height: 100px;
      }
      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
    &__meta {
      text-align: left;
      flex: 0 1 auto;
      width: auto;
      padding-left: var(--base-padding-medium);
      align-self: center;
      overflow: hidden;
      > * {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &__name {
        display: block;
        font-weight: 500;
      }
      &__phone {
        display: block;
        font-size: 80%;
        color: var(--color-gray);
      }
    }
  }
}
