.site-header-dropdown {
  display: none;
  visibility: hidden;
  height: 0;
  width: 0;
  background: #1e1f1f;
  position: fixed;
  top: 100px;
  margin-top: -50px;
  right: 0;
  max-width: 90%;
  z-index: 9999;
  padding: 2rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .5);
  opacity: 0;

  @media (--large) {
    display: block;
  }

  &.js-active {
    display: block;
    height: auto;
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    width: 55rem;
    transition: opacity .15s, margin .15s;
  }

  &__title {
    color: var(--color-white);
    padding: 0;
    margin: 0 0 1rem;
  }

  &__arrow {
    width: 20px;
    height: 20px;
    background: #1e1f1f;
    position: absolute;
    top: -10px;
    right: 0;
    transform: rotate(45deg);
  }
}

.site-header-dropdown-grid {
  display: flex;
  padding: 0;
  margin: 0 -.5rem;
  list-style-type: none;

  &:hover li {
    opacity: .8;
  }

  li:hover {
    opacity: 1;

    .site-header-dropdown-grid__item__image {
      border: 2px solid rgba(0, 160, 80, 0);

      &::before, &::after {
        -webkit-transform: scale(1);
      }
    }
  }
  &__item {
    flex: 0 0 25%;
    transition: opacity .15s ease-in-out;

    a {
      padding: .5rem;
      display: block;
    }

    &__image {
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      position: relative;
      transition: .5s;
      border: 2px solid transparent;
      -webkit-transition: .5s;

      &::before, &::after {
        width: auto;
        height: auto;
        z-index: 3;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-sizing: border-box;
        -webkit-transform: scale(0);
        transition: .35s;
      }

      &::before {
        border-bottom: 2px solid var(--color-border-active);
        border-left: 2px solid var(--color-border-active);
        -webkit-transform-origin: 0 100%;
        left: -2px;
      }

      &::after {
        border-top: 2px solid var(--color-border-active);
        border-right: 2px solid var(--color-border-active);
        -webkit-transform-origin: 100% 0%;
        right: -2px;
      }
    }
    &__title {
      color: var(--color-white);
    }
  }
}

.site-search-dropdown {
  visibility: hidden;
  width: auto;
  background: #1e1f1f;
  margin-top: -50px;
  z-index: 9999;
  padding: 2rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .5);
  opacity: 0;
  top: 100%;
  left: 0;
  right: 0;
  position: absolute;
  height: auto;
  min-height: 20rem;
  max-width: 100%;

  &.js-active {
    width: auto;
    display: block;
    height: auto;
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    transition: opacity .15s, margin .15s;
  }

  .site-header__search__popular {
    width: 300px;
    max-width: 30%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    line-height: 2.25rem;
  }
}
