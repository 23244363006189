.empty-state {
  max-width: 60rem;
  text-align: center;
  margin: 0 auto;
  padding: var(--external-gutters) 0;
  &__title {
    text-transform: none;
    font-family: var(--font-secondary);
    font-weight: 500;
    font-size: 38px;
    letter-spacing: .15em;
  }
  &__text {
    font-family: var(--font-secondary);
    color: var(--color-gray);
    font-size: 110%;
  }
  &__image {
    position: relative;
    margin-top: -20%;
    width: 100%;
    height: 0;
    padding-bottom: 40%;
    overflow: hidden;
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
