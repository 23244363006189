.index-listings {
  display: flex;
  overflow: hidden;
  height: calc(100vh - var(--header-height) - var(--listings-control-height));

  &__listings {
    background: #000;
    padding: 30px;
    flex: 1.5 0;
    overflow-y: scroll;
    height: 100%;
    color: #fff;

    .epl-paginate-default-wrapper {
      color: #fff;

      a {
        color: #fff;
      }
    }
  }

  &__map {
    flex: 1 auto;
    overflow: hidden;
    height: 100%;

    .slider-map {
      height: calc(100vh - var(--header-height) - var(--listings-control-height)) !important;
    }
  }
}

.index-controls {
  background: #fff;
  height: 70px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .15);
  position: relative;
  z-index: 5;
}
