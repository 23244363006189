.hero {
  min-height: 200px;
  display: flex;
  align-items: center;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 2rem;
  position: relative;

  &__contents {
    text-align: center;
    margin: 0 auto;
  }

  h1 {
    font-family: var(--font-serif);
    text-transform: inherit;
    font-size: 8vw;
    margin: 0 auto;
    color: white;
    flex: none;
    letter-spacing: 0;
  }

  @media (--medium) {
    min-height: 25rem;
    height: 50vh;

    h1 {
      font-size: 8vw;
    }
  }

  &--large {
    @media (--medium) {
      min-height: 80vh;

      h1 {
        font-size: 8vw;
      }
    }
  }
}

.hero-weather {
  background: rgba(0, 0, 0, .5);
  border-radius: 3px;
  line-height: 2.5rem;
  color: var(--color-white);
  font-weight: 100;
  font-size: 1rem;
  letter-spacing: .1em;
  text-shadow: 0 0 20px rgba(0, 0, 0, .5);
  width: auto;
  margin: 0 auto;
  display: inline-block;
  padding: .15rem 1rem;

  img {
    float: left;
    height: 2.5rem;
    width: auto;
  }

  @media (--medium) {
    font-size: 1.25rem;
    line-height: 3rem;

    img {
      height: 3rem;
    }
  }
}
