.page-header--image {
  display: block;
  text-align: center;
  padding: 3rem var(--external-gutters);
  background-position: center center;
  background-size: cover;
  position: relative;
  // margin-bottom: var(--section--vertical-padding);
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background: rgba(0, 0, 0, .2);
    display: block;
  }
  h1 {
    margin-bottom: 0;
    color: var(--color-white);
    z-index: 1;
    position: relative;
  }
}
