.tile {
  background-color: #000;
  background-position: center center;
  background-size: 105% 105%;
  display: block;
  padding-bottom: 17%;
  width: 100%;
  position: relative;
  text-decoration: none;
  border-bottom: 0;
  transition: all .2s ease-in-out;
  outline: none;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .1);
    opacity: 0;
    transition: all .2s;
  }

  &:hover {
    opacity: 1;
    &::before {
      opacity: 1;
    }
  }
}

.tile__price {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  background: #000;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
}

.tile__content {
  position: absolute;
  bottom: 0;
  width: auto;
  padding: 20px;

  * {
    color: #fff;
  }

  h3, h4 {
    font-family: var(--font);
    margin: 0;
    letter-spacing: 0;
    text-transform: capitalize;
  }

  h3 {
    font-size: 32px;
    font-weight: 100;
  }

  h4 {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: .5px;

    * {
      letter-spacing: .5px;
      text-transform: none;
    }
  }

  p {
    margin: 0 0 10px;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }

  &__meta {
    font-size: 10px;
    font-weight: 500;
    letter-spacing: .85px;
    opacity: .75;
    text-transform: uppercase;
    margin: 0;
    padding-top: 10px;
    display: block;
    list-style-type: none;

    li {
      margin: 0;
      padding: 0;
    }
  }
}
