.tile-set {
  display: flex;
  margin: 0 -5px;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .tile {
    flex-grow: 1;
    flex-basis: 100%;
    margin: 5px;
    padding-bottom: 65%;
  }

  &--tall {
    .tile {
      padding-bottom: 45%;
    }
  }

  &--wide {
    .tile {
      padding-bottom: 45%;
    }
  }

  &--2 {
    flex-wrap: wrap;

    .tile {
      flex-basis: calc(50% - 10px);
      padding-bottom: 30%;
    }
  }

  &--4 {
    flex-wrap: wrap;

    .tile {
      @media (--medium) {
        padding-bottom: 17%;
        flex-basis: calc(25% - 10px);
      }
    }
  }
}
