.site-footer {
  padding: var(--external-gutters);
  background: var(--black);
  border-top: 1px solid #292929;

  &__info {
    padding-bottom: var(--external-gutters);

    &__logo {
      a {
        font-family: var(--font-serif);
        color: white;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 300;
        font-size: 1.5rem;
        height: var(--height);
        line-height: var(--height);
        display: block;
      }
    }
  }

  &__menus {
    display: flex;

    &__menu {
      flex: 0 1 calc(100% / 3);
    }
  }

  &__sub {
    padding: var(--external-gutters) 0 0;
    opacity: .2;
  }
}

.post-type-archive-property {
  .site-footer {
    display: none;
  }
}
