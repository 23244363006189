/* Paragraphs */
p {
  font-weight: 300;
  letter-spacing: .05em;
  font-family: var(--font);
  margin-bottom: 2rem;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-serif);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .3em;
  margin-top: 0;
  margin-bottom: 0;
}

h1, .h1-size {
  font-size: 161%;
  @media (--medium) {
    font-size: 305%;
  }
}

h2, .h2-size {
  font-size: 140%;
  @media (--medium) {
    font-size: 230%;
  }
}

h3, .h3-size {
  font-size: 133%;
  @media (--medium) {
    font-size: 195%;
  }
}

h4, .h4-size {
  font-size: 121%;
  @media (--medium) {
    font-size: 156%;
  }
}

h5, .h5-size {
  font-size: 110%;
  @media (--medium) {
    font-size: 125%;
  }
}

h6, .h6-size {
  font-size: 100%;
}

h1, h2, h3, h4, h5, h6 {
  .pre {
    display: block;
    font-size: 40%;
    font-family: var(--font-secondary);
    line-height: 1;
    letter-spacing: .6em;
    margin-bottom: .25rem;
  }
}

.tiny {
  font-size: 75%;
  letter-spacing: .3em;
  margin-bottom: .5rem;
  opacity: .6;
}

.sans {
  font-family: var(--font-secondary);
}

.no-top-margin {
  margin-top: 0;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.text-inverted {
  color: white;
  p, h1, h2, h3, h4, h5, h6 {
    color: white;
  }
}

/* Links */
a {
  text-decoration: none;
  color: var(--color-black);

  &:hover {
    opacity: .8;
  }

  .text & {
    border-bottom: 1px dashed var(--color-gray-50);
    transition: .1s ease-in-out;

    &:hover {
      color: var(--color-gold);
      border-bottom-color: var(--color-gold);
    }
  }
}

.text-inverted a {
  color: var(--color-white);
}

.text-uppercase {
  text-transform: uppercase;
}

.fw400 { font-weight: 400; }
.fw500 { font-weight: 500; }
.fw600 { font-weight: 600; }
.fw700 { font-weight: 700; }

/* Labels */
.label {
  font-size: .55rem;
  font-family: var(--font-secondary);
  display: block;
  letter-spacing: .2em;
  color: var(--color-white);
  opacity: .5;
  font-weight: 300;
}
