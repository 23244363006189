/* Header Vars */
:root {
  --site-header--height: 3.125rem;
  --site-header--padding: 1rem;
}

.site-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: var(--zi-nav);
  padding: var(--site-header--padding);
  display: flex;
  transition: background-color .15s ease-in-out;
  background-color: var(--color-black);

  /* @todo: better way to donate which pages get what header */
  .home &, .single-cities & {
    background-color: transparent;

    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 100px;
      opacity: .25;
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000 100%);
      transition: opacity .15s ease-in-out;
      z-index: -1;
    }
  }

  &__title {
    flex: 0 0 3rem;
    width: 3rem;
    margin: 0 1rem 0 0;
    text-align: center;

    @media (--medium) {
      width: 12rem;
      flex-basis: 12rem;
    }

    h1 {
      height: var(--site-header--height);
      line-height: var(--site-header--height);
      width: auto;
      padding: 0;
      margin: 0;
    }

    p {
      margin: 0;
      padding: 0;
    }

    a {
      font-family: var(--font-serif);
      color: white;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 300;
      font-size: 1.5rem;
      height: var(--site-header--height);
      line-height: var(--site-header--height);
      display: block;
    }

    &--large {
      display: none;
    }

    @media (--medium) {
      &--large {
        display: inherit;
      }

      &--small {
        display: none;
      }
    }
  }

  &__search {
    flex: 3 1 100%;
    position: relative;

    @media (--large) {
      flex: 1 1 100%;
    }

    input[type='submit'], .screen-reader-text {
      display: none;
    }

    input[type='text'] {
      height: var(--site-header--height);
      line-height: var(--site-header--height);
      background: var(--color-black);
      transition: all .2s ease-in-out;
      border: 1px solid #2b2b2b;
      width: 100%;

      &:hover {
        background-color: var(--color-gray-40);
      }

      &:focus {
        background-color: var(--color-white);
      }
    }

    .epl-search-label {
      display: none;
    }
  }

  &__mobile-nav-toggle {
    flex: 0 0 3rem;
    width: 3rem;
    margin: 0 0 0 1rem;
    color: var(--color-white);
    text-align: center;
    line-height: var(--site-header--height);

    @media (--large) {
      display: none;
    }
  }

  &__nav {
    display: none;
    flex: 0 0 auto;
    margin-left: 1rem;
    margin-right: -1rem;

    @media (--large) {
      display: inherit;
    }

    .menu {
      padding: 0;
      margin: 0;

      &-item {
        a {
          display: block;
          height: var(--site-header--height);
          font-size: 1rem;
          line-height: var(--site-header--height);
          color: var(--color-white);
          text-transform: uppercase;
          font-weight: 300;
          padding: 0 1rem;
          letter-spacing: .08rem;
          &:hover {
            opacity: .8;
          }
        }
      }
    }
  }

  &--scrolled {
    /* @todo: better way to donate which pages get what header */
    .home &, .single-cities & {
      background: var(--color-black);

      &::after {
        opacity: 0;
      }
    }
  }
}

.drawer-nav-toggle {
  color: var(--color-white);
}

.drawer-nav {
  position: fixed;
  top: -100vh;
  right: -100vw;
  opacity: 0;
  width: 102vw;
  height: 102vh;
  margin: -1vh -1vw;
  background: var(--color-black);
  z-index: var(--zi-drawer-nav);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(.1);
  transition: all .35s ease-in-out;

  &.js-drawer-nav-open {
    top: 0;
    right: 0;
    transform: scale(1);
    opacity: 1;
  }

  &__contents {
    flex: 0 1 auto;
    text-align: center;
  }
}

.drawer-nav .menu {
  li {
    display: block;
    line-height: 4rem;

    a {
      font-size: 2rem;
      font-weight: 300;
      width: 60vw;
      display: block;
      transition: background-color .075s ease-in-out;
      text-transform: uppercase;

      &:hover {
        background: rgba(255, 255, 255, .1);
      }
    }
  }
}
