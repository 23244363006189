/* Media Queries */
@custom-media --xsmall-only  (max-width: 399px);
@custom-media --small-only   (max-width: 699px);
@custom-media --small        (min-width: 400px);
@custom-media --medium       (min-width: 700px);
@custom-media --large        (min-width: 1000px);
@custom-media --xlarge       (min-width: 1400px);
@custom-media --xxlarge      (min-width: 1800px);

/* Typography */
:root {
  --font: "acumin-pro-condensed", "Helvetica", sans-serif;
  --font-secondary: "Helvetica", sans-serif;
  --font-serif: "essonnes-headline", "Georgia", serif;
  --font-size-base: 1rem;
}

/* Spacing */
:root {
  --base: 4px;
  --base-padding-xsmall: calc(var(--base) * 1.5);
  --base-padding-small: calc(var(--base) * 3);
  --base-padding-medium: calc(var(--base) * 4);
  --base-padding-large: calc(var(--base) * 6);
  --base-padding-xlarge: calc(var(--base) * 8);
}

/* Colors */
:root {
  --black: #000;
  --color-black: #000;
  --color-white: #fff;
  --color-gray: #777;
  --color-dark-gray: #1e1f1f;
  --color-light-gray: #f5f5f6;
  --color-gold: #ad8635;
  --color-border: #dcdcdc;
  --color-border-active: #a68743;
  --color-border-active-shadow: rgba(166, 135, 67, .25);
}

/* Dimensions */
:root {
  --header-height: 90px;
  --listings-control-height: 70px;
}

/* Z-index */
:root {
  --zi-nav: 1000;
  --zi-drawer-nav: 999;
}

/* Grid Vars */
:root {
  --external-gutters: 1rem;
  @media (--medium) {
    --external-gutters: 1.5rem;
  }
  @media (--large) {
    --external-gutters: 4rem;
  }
  @media (--xlarge) {
    --external-gutters: 7rem;
  }
}
