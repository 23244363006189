/* Section Vars */
:root {
  --section--vertical-padding: 2rem;
  @media (--medium) {
    --section--vertical-padding: 3rem;
  }
  @media (--large) {
    --section--vertical-padding: 5rem;
  }
  @media (--xlarge) {
    --section--vertical-padding: 6rem;
  }
}

section {
  /* Placeholder */
}

.section-content {
  padding: var(--section--vertical-padding) var(--external-gutters);
  &--narrow {
    max-width: calc(68rem + var(--external-gutters) * 2);
    margin: 0 auto;
  }
  &--text {
    max-width: calc(50rem + var(--external-gutters) * 2);
    margin: 0 auto;
    p {
      text-align: justify;
    }
  }
  &--condensed {
    padding: calc(var(--section--vertical-padding) / 2) var(--external-gutters);
  }
}

.section-sep {
  height: 1px;
  width: 100%;
  background: rgba(137, 137, 137, .3);
  border: none;
}
