.post-type-archive-property {/* Search Page Styles */
  #primary {
      .update-search {
        width: 100%;
        position: absolute;
        top: 7px;
        text-align: center;
        display: none;
        a {
          background: #000;
          color: #FFF;
          padding: 10px 20px;
        }
      }
      .slider-map-zoom-out, .slider-map-zoom-in {
        background-color: #000;
      }
      .overlay-featured-marker.infowindow {
      left: -80px !important;
      top: -125px !important;
      height: 205px !important;
      background-color: #000;
      span.title {
        color: #FFF;
      }
      .content {
        color: #FFF !important;
        .slider-price {
          display: none;
        }
        .epl-adv-popup-meta {
          .icon {
            float: none;
            padding-left: 0;
            &.beds:before {
              content: "Bedrooms";
            }
            &.bath:before {
              content: "Bathrooms";
            }
          }
          //display: none;
        }
      }
      span.arrow {
        display: none;
      }
    }
    .index-listings__map {
      position: relative;
      .featured-listing {
        width: 100%;
        position: absolute;
        bottom: 0;
        text-align: center;
        display: none;
        .featured-title {
          width: 80%;
          color: #fff;
          background-color: #000;
          text-align: left;
          margin: 0 auto;
          font-size: 11px;
          font-weight: 100;
          padding: 10px 15px;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
        .wrapper {
          margin: 0 auto;
          background-color: #000;
          width: 80%;
        }
        .epl-listing-post {
          display: inline-block;
          width: 49%;
          position: relative;
          .property-content {
            color: #FFFFFF;
            text-align: left;
            .property-feature-icons {
              display: none;
            }
            .property-address {
              position: absolute;
              bottom: 20px;
              span.item-street {
                font-size: 12px;
                margin: 10px;
                color: #fff;
                font-weight: 100;
              }
              span.entry-title-sub {
                display: none;
              }
            }
            .price {
              position: absolute;
              top: 0;
              right: 0;
              span {
                background-color: #ad8625;
                font-weight: 100;
                font-size: 16px;
                padding: 7px;
              }
            }
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .epl-property_address, .epl-search-btn {
      display: none;
    }
    .epl-search-form {
      display: none;
    }

    --height: 3.125rem;
    --calcheight: calc(var(--height) + ((var(--external-gutters) / 2.5) * 2));
    --xlcalcheight: calc(var(--height) + ((var(--external-gutters) / 7) * 2));
    @media (--xlarge) {
      padding-top: var(--xlcalcheight);
    }
    .search-controls {
      background: var(--color-white);
      height: var(--calcheight);
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .15);
      position: relative;
      z-index: 5;
      padding: var(--external-gutters) 2rem 0 2rem;
      @media (--xlarge) {
        height: var(--xlcalcheight);
        padding: calc(var(--external-gutters) / 2.5) 2rem 0 2rem;
      }
      div[id^="slider-"] {
        float: left;
        width: 12%;
        background: var(--color-black);
        box-shadow: unset;
        height: 1px;
        border-radius: 1px;
        border: 1px solid var(--color-black);
        .noUi-connect {
          background: var(--color-black);
          box-shadow: unset;
          top: -3px;
          bottom: -3px;
          border-radius: 4px;
        }
        .noUi-handle {
          border: 3px solid var(--color-black);
          border-radius: 3px;
          background: var(--color-black);
          box-shadow: 0 0 1px var(--color-white), 0 0 1px var(--color-white), 0 0 0 2px var(--color-white);
          width: 6px;
          height: 17px;
          left: 0;
          top: -9px;
          &:after, &:before {
            display: none;
          }
          &:after {
            display: block;
            border: none;
            background: transparent;
            width: 34px;
            height: 28px;
            left: -17px;
            top: -8px;
          }
          &:focus{
            outline: none;
          }
        }
        .noUi-tooltip {
          padding: 0;
          border: none;
          background-color: transparent;
        }
      }
      .text-criteria {
        float: left;
        padding-right: 20px;
        margin-top: -10px;
        color: var(--color-gold);
        font-size: 12px;
      }
      .criteria:not(:first-child) {
        .text-criteria {
          margin-left: 5%;
        }
      }
      .criteria-dropdowns {
        span.select2-selection.select2-selection--single {
          outline: none;
        }
        .select2-container {
          margin-left: 2%;
          margin-top: -40px;
        }
        .select2-selection__placeholder {
          font-size: 10px;
        }
        .select2-container--default .select2-selection--single .select2-selection__rendered {
          line-height: 24px;
        }
        span.select2-selection.select2-selection--multiple {
          ul.select2-selection__rendered {
            padding-right: 15px !important;
            max-height: 24px;
          }

          ul.select2-selection__rendered:after {
            content: "";
            position: absolute;
            right: 10px;
            top: 50%;
            border-color: #888 transparent transparent transparent;
            border-style: solid;
            border-width: 5px 4px 0 4px;
            height: 0;
            margin-left: -4px;
            margin-top: -2px;
            width: 0;
          }
          //overflow: hidden;
          max-height: 28px;
          min-height: 28px;
          .select2-search--inline {
            margin-top: 0;
            font-size: 12px;
            line-height: 28px;
            margin-left: 4px;
            display: inline-block;
          }
          .select2-search__field::-webkit-input-placeholder {
            color: #999 !important;
          }
          .select2-selection__choice {
            display: inline-block;
            position: relative;
            font-size: 13px;
            margin-top: unset;
            float: none;
            border-radius: unset;
            border: none;
            background-color: #fff;

          }
          .select2-selection__choice__remove {
            display: none;
          }
        }
      }
    }
    .epl-archive-utility-wrapper {
      margin-bottom: 20px;
      span.select2-selection.select2-selection--single {
        outline: none;
      }
    }
    .epl-switch-view {
      display: none;
    }
    .epl-switching-sorting-wrap {
      float: right;
    }
    .tile-set.tile-set--2 {
      clear: both;
    }
    a.page.larger,
    a.nextpostslink,
    a.previouspostslink,
    a.page.smaller{
      color: #FFF;
      padding: 0 10px;
    }
    span.current {
      color: var(--color-gray);
      padding: 0 10px;
    }
  }
  label[for="property_address"].epl-search-label.fm-label{
    display: none;
  }
  /* Forms */

  input, textarea {
    width: auto;
    border: none;
    background-color: var(--color-gray);
    padding: var(--base-padding-small) var(--base-padding-medium);

    &:focus {
      outline: none;
    }
  }

  /* Search */
  input[name="property_address"]{
    border: 1px solid #000;
    display: inline-block;
    background: #fff;
    transition: all .2s ease-in-out;
    color: white;
    width: 100%;

    .site-header & {
      background: #000;
      border: 1px solid rgba(255, 255, 255, .2);
    }

    &:focus {
      background: #fff;
      color: #000;
    }
  }

  .search-submit {
    display: none;
  }
}
