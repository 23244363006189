.news {
  @media (--medium) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 calc(var(--external-gutters) * -.75) 0 calc(var(--external-gutters) * -.75);
    width: 100%;
  }
  &__item {
    &:hover {
      background-color: rgba(0, 0, 0, .2);
      border-radius: 5px;
    }
    @media (--small-only) {
      margin-bottom: var(--external-gutters);
      display: block;
      width: 100%;
    }
    @media (--medium) {
      flex: 0 1 calc(100% / 3);
      padding: 1.5rem;
    }
    @media (--large) {
      flex-basis: 25%;
    }
    img {
      width: 100%;
    }
  }
}
