.slider {
  position: relative;
  color: white;
  overflow: hidden;
  ul, ol, li {
    list-style: none;
    margin: 0;
    padding: 0;
    border: none;
  }
  .slick-arrow {
    position: absolute;
    left: 20px;
    cursor: pointer;
    text-indent: -9999em;
    width: 80px;
    height: 80px;
    background-image: url('../images/demo/unslider_arrow_white.png');
    background-size: 20px auto;
    display: block;
    z-index: 20;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    outline: none;
    top: 50%;
    margin-top: -40px;
    &:hover {
      opacity: .5;
    }
    &.slick-next {
      transform: rotate(180deg);
      left: auto;
      right: 20px;
    }
  }
}

.slider--overflow {
  margin: 0 calc(var(--external-gutters) * -1) 0 calc(var(--external-gutters) * -1);
  margin-top: 2rem;
  padding-bottom: 30px;
  .slick-slide {
    transform: scale(.9);
    transition: all .5s;
    z-index: 9;
    position: relative;
    opacity: .5;
    &.slick-center {
      transform: scale(1.08);
      z-index: 10;
      opacity: 1;
    }
  }
  .slick-list {
    overflow: visible;
  }
  .slick-arrow {
    position: absolute;
    left: 20px;
    cursor: pointer;
    text-indent: -9999em;
    width: 40px;
    height: 40px;
    background-image: url('../images/demo/unslider_arrow_black.png');
    background-size: 10px auto;
    display: block;
    z-index: 20;
    background-position: center center;
    background-repeat: no-repeat;
    bottom: 0;
    top: auto;
    transform: translateY(-20%);
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    outline: none;
    &:hover {
      opacity: .5;
    }
    .bg-black & {
      background-image: url('../images/demo/unslider_arrow_white.png');
    }
    &.slick-next {
      transform: rotate(180deg);
      left: auto;
      right: 20px;
    }
  }
}

/*
.slider-nav {
  display: none !important;
  ol {
    list-style: none;
    text-align: center;
    li {
      display: inline-block;
      width: 6px;
      height: 6px;
      margin: 0 4px;
      background: transparent;
      border-radius: 5px;
      overflow: hidden;
      text-indent: -999em;
      border: 2px solid #fff;
      cursor: pointer;
      &.slider-active {
        cursor: default;
      }
    }
  }
}
*/
