.utility-bar {
  background-color: var(--color-light-gray);
  padding: var(--base-padding-large);
  display: flex;
  justify-content: center;
  > * {
    margin: 0 var(--base-padding-small);
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.utility-bar input[type="search"] {
  flex: 0 1 30%;
}

.utility-bar select {
  flex: 0 1 30%;
}

.utility-bar input[type="submit"] {

}
