/* Brand Colors */
:root {
  --color-gold: #ad8625;
}

/* Monotone Colors */
:root {
  --color-black: #000;
  --color-gray-20: #2a2a2a;
  --color-gray-30: #292929;
  --color-gray-40: #464646;
  --color-gray-50: #767676;
  --color-gray-60: #e5e5e5;
  --color-gray-70: #f7f7f7;
  --color-white: #fff;
}


.bg-black { background-color: var(--color-black); }
