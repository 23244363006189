.single-cities {
  .featured-agents {
    max-width: calc(50rem + var(--external-gutters) * 2);
    margin: 0 auto;
    &__agent {
      margin: 1.5rem;
      padding: 1.5rem;
      &__image {
        width: 25%;
        float: left;
        padding-right: 1.5rem;
      }
      &__info {
        width: 75%;
      }
    }
    @media (--medium) {
      display: flex;
      &__agent {
        flex: 0 1 50%;
      }
    }
  }
}
