/* Forms */

input, textarea, input[type="search"], select {
  width: auto;
  background-color: var(--color-white);
  border: 1px solid var(--color-border);
  padding: var(--base-padding-small) var(--base-padding-medium);
  border-radius: 0;
  transition: all .15s;

  &:focus, &:active {
    outline: none;
    border-color: var(--color-border-active);
    box-shadow: 0 2px 10px 0 var(--color-border-active-shadow);
  }
}

/* Search */
.search-field {
  border: 1px solid #000;
  display: inline-block;
  background: #fff;
  transition: all .2s ease-in-out;
  color: white;

  .site-header & {
    background: #000;
    border: 1px solid rgba(255, 255, 255, .2);
  }

  &:focus {
    background: #fff;
    color: #000;
  }
}

.search-submit {
  display: none;
}

/* Gravity Forms, probably general later */
.gform_body {
  * {
    font-family: var(--font-sans) !important;
  }
  input, textarea {
    min-height: 3rem;
    padding: .5rem 1rem !important;
  }
  .gfield {
    margin-bottom: 2rem;
  }
}
