html {
  margin-top: 0 !important;
  font-family: var(--font-secondary);
  font-size: calc(var(--font-size-base) * .85);
  line-height: 1.6;
  @media (--medium) {
    font-size: var(--font-size-base);
  }
}

* {
  box-sizing: border-box;
}

.site-content.wrapper {
  padding-top: calc(var(--site-header--height) + (var(--site-header--padding) * 2));
  .home &, .single-cities & {
    padding-top: 0;
  }
}
