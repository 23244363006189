.border--fancy {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 5px;
    bottom: 0;
    left: 5px;
    border: 1px solid rgba(137, 137, 137, .3);
  }
  &::after {
    content: "";
    position: absolute;
    top: 5px;
    right: 0;
    bottom: 5px;
    left: 0;
    border: 1px solid rgba(137, 137, 137, .3);
  }
}
