.button {
  display: inline-block;
  line-height: 24px;
  padding: .7rem 1rem;
  background: var(--color-black);
  color: white;
  text-align: center;
  &:hover {
    opacity: .9;
  }
  &:focus {
    &:not(:active) {
      outline: none;
      box-shadow: inset 0 0 0 1px var(--color-border-active), 0 4px 14px 1px var(--color-border-active-shadow);
    }
  }
}

.button--inverted {
  background-color: var(--color-white);
  color: var(--color-black);
}

.button--outline {
  border: 1px solid var(--color-black);
  background-color: transparent;
  color: var(--color-black);
  &.button--inverted {
    color: var(--color-white);
    border-color: var(--color-white);
  }
}

.button-row {
  .button {
    margin-left: .5rem;
    &:first-of-type {
      margin-left: 0;
    }
  }
}
