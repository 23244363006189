/* Menu */

.menu {
  display: inherit;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: inline-block;
  }

  a {
    padding: var(--base-padding-medium);
    color: var(--color-white);
  }
}
