/*
Utilites are named using the first letters of each syllable, with hyphens separating properties and values.

For Example:
display: inline-block; = .dp-ilb

*/

/* Display */
.dp-n        { display: none; }
.dp-ih       { display: inherit; }
.dp-b        { display: block; }
.dp-ilb      { display: inline-block; }
.dp-il       { display: inline; }

/* Visibility */
.vibly-hd    { visibility: hidden; }
.vibly-vsb   { visibility: visible; }

/* Position */
.pos-rel { position: relative; }

/* Text Alignment */
.ta-center { text-align: center; }

/* Background Color */
.bg-black { background-color: var(--black); }
.bg-dark-gray { background-color: var(--color-dark-gray); }

/* Margin */
.mt0 { margin-top: 0; }
.mb0 { margin-bottom: 0; }
.my0 {
  margin-top: 0;
  margin-bottom: 0;
}

/* Overflows */
.ovh { overflow: hidden; }
