.l-sidebar {
  background: var(--color-black);
  padding: var(--external-gutters);

  .widget-area {
    display: none;
  }

  * {
    color: white;
    font-family: Helvetica;
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  }
}
