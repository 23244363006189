.tag {
  background-color: var(--color-black);
  padding: var(--base);
  color: var(--color-white);
  font-size: 60%;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: .2rem;
}

/* Themes */
.tag--featured {
  background-color: #ad8635;
  
}
