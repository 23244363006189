@media (--medium) {
  .flex-grid {
    display: flex;
    width: auto;
    margin: 0 calc(var(--external-gutters) * -1);
    &--reversed {
      flex-direction: row-reverse;
    }
  }
}

.col {
  flex: 0 1 auto;
  margin: var(--external-gutters);
}

.l-grid {
  align-items: stretch;
  display: flex;

  &--full-height {
    height: 100%;
    min-height: 100%;
  }

  &__column {
    align-items: stretch;
    flex: 1 1 0%;
    min-width: 0;

    &--sidebar-right {
      flex-grow: 1;
      flex-shrink: 0;
      display: flex;
      min-width: 250px;
      max-width: 450px;
    }

    &--medium {
      flex-grow: 2;
    }

    &--large {
      flex-grow: 3;
    }
  }
}

.l-section {
  padding: var(--external-gutters);

  &--no-gutters {
    padding: 0;
  }

  &--l {
    padding: var(--external-gutters--l);
  }
}

.basic-grid {
  &__row {
    display: flex;
    width: auto;
  }
  &__column {
    flex: 0 1 auto;
  }
}
